.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.react-datepicker__time-list {
  scroll-behavior: smooth;
}

.react-datepicker__day--excluded {
  text-decoration: line-through;
}

#intakeContainer {
  font-family: Oswald !important;
}

#intakeContainer .ui.header {
  font-family: Oswald !important;
}

#intakeContainer .ui.button {
  font-family: Oswald !important;
}
