body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000 !important;
}

:root {
  color-scheme: light dark; /* both supported */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker-wrapper {
  display: flex !important;
}

#pageModal > .close {
  color: #ffffff;
  top: 10px;
  right: 10px;
  opacity: 1;
}

#pageModal > .close:hover {
  color: #ffffff;
  opacity: 0.8;
}

#pageModal > .close:active {
  color: #ffffff;
  opacity: 0.5;
}

.ui.search > .results {
  overflow-y: auto;
  max-height: 200px;
}

.ui.sortable.table thead th:hover {
  background: #f3f3f3 !important;
}

.smooth-image {
  transition: opacity 0.5s;
}
.image-visible {
  opacity: 1;
}
.image-hidden {
  opacity: 0;
}

@media (max-width: 730px) {
  #wrapFields .field {
    width: 100%;
    margin: 5px;
  }
}

@media (min-width: 731px) and (max-width: 1399px) {
  #wrapFields .field {
    width: calc(50% - 10px);
    margin: 5px;
  }
}

@media (min-width: 1400px) {
  #wrapFields .field {
    width: calc(25% - 20px);
    margin: 5px;
  }
}

#addeditlead > .field > label {
  font-size: 1.1em !important;
}

.custom-button:hover {
  cursor: pointer;
  filter: brightness(90%);
}

.custom-button:active {
  cursor: pointer;
  filter: brightness(80%);
}

label {
  font-size: 1.1em !important;
}

.field > span {
  font-size: 1.1em !important;
}
